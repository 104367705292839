
export default {
  props: {
    tag: {
      type: String,
      default: 'p',
    },

    level: {
      type: String,
      default: '200',
      validator: (value) =>
        [
          'round',
          '50',
          '100',
          '200',
          '300',
          '400',
          '500',
          '600',
          '700',
          '800',
          '900',
          '1000',
        ].includes(value),
    },
  },
}
