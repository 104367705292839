export const state = () => ({
  rooms: [],
  loading: false,
})

export const actions = {
  async createRoom({ commit }, { projectId, floorId, roomData }) {
    commit('setLoading', true)
    const { data } = await this.$axios.$post(
      `/projects/${projectId}/floors/${floorId}/rooms`,
      roomData
    )
    commit('setLoading', false)

    return data
  },

  async updateRoom({ commit }, { projectId, floorId, roomId, roomData }) {
    commit('setLoading', true)
    const { data } = await this.$axios.$put(
      `/projects/${projectId}/floors/${floorId}/rooms/${roomId}`,
      roomData
    )
    commit('setLoading', false)

    return data
  },

  async deleteRoom({ commit }, { projectId, floorId, roomId }) {
    commit('setLoading', true)
    await this.$axios.$delete(
      `/projects/${projectId}/floors/${floorId}/rooms/${roomId}`
    )
    commit('setLoading', false)
  },

  async addProduct(
    { commit },
    { projectId, floorId, roomId, productId, x, y }
  ) {
    commit('setLoading', true)
    const { data } = await this.$axios.$post(
      `/projects/${projectId}/floors/${floorId}/rooms/${roomId}/products`,
      { product_id: productId, x, y }
    )
    commit('setLoading', false)

    return data
  },

  async updateProduct(
    { commit },
    { projectId, floorId, roomId, productId, relationId, x, y }
  ) {
    commit('setLoading', true)
    const { data } = await this.$axios.$put(
      `/projects/${projectId}/floors/${floorId}/rooms/${roomId}/products/${productId}/relation/${relationId}`,
      { x, y }
    )
    commit('setLoading', false)

    return data
  },

  async removeProduct(
    { commit },
    { projectId, floorId, roomId, productId, relationId }
  ) {
    commit('setLoading', true)
    const { data } = await this.$axios.$delete(
      `/projects/${projectId}/floors/${floorId}/rooms/${roomId}/products/${productId}/relation/${relationId}`
    )
    commit('setLoading', false)

    return data
  },
}

export const mutations = {
  setRooms(state, rooms) {
    state.rooms = rooms
  },

  setLoading(state, loading) {
    state.loading = loading
  },
}

export const getters = {}
