// ~/plugins/vuex-persist.js
import { VuexPersistence } from 'vuex-persist'

export default ({ store }) => {
  new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({
      navigation: {
        sidebarCollapsed: state.navigation.sidebarCollapsed,
      },
    }),
  }).plugin(store)
}
