
import throttle from 'lodash/throttle'

export default {
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },

  auth: false,

  data() {
    return {
      scrolled: false,
    }
  },

  beforeMount() {
    this.scrollHandler()
    window.addEventListener('scroll', this.scrollHandler)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler)
  },

  methods: {
    scrollHandler: throttle(function () {
      requestAnimationFrame(() => {
        this.scrolled = window.pageYOffset > 100
      })
    }, 100),
  },
}
