export const AddImage = () => import('../../components/icons/AddImage.vue' /* webpackChunkName: "components/add-image" */).then(c => wrapFunctional(c.default || c))
export const ArrowLeft = () => import('../../components/icons/ArrowLeft.vue' /* webpackChunkName: "components/arrow-left" */).then(c => wrapFunctional(c.default || c))
export const ArrowRight = () => import('../../components/icons/ArrowRight.vue' /* webpackChunkName: "components/arrow-right" */).then(c => wrapFunctional(c.default || c))
export const Check = () => import('../../components/icons/Check.vue' /* webpackChunkName: "components/check" */).then(c => wrapFunctional(c.default || c))
export const CheckBadge = () => import('../../components/icons/CheckBadge.vue' /* webpackChunkName: "components/check-badge" */).then(c => wrapFunctional(c.default || c))
export const ChevronDown = () => import('../../components/icons/ChevronDown.vue' /* webpackChunkName: "components/chevron-down" */).then(c => wrapFunctional(c.default || c))
export const ChevronLeft = () => import('../../components/icons/ChevronLeft.vue' /* webpackChunkName: "components/chevron-left" */).then(c => wrapFunctional(c.default || c))
export const ChevronRight = () => import('../../components/icons/ChevronRight.vue' /* webpackChunkName: "components/chevron-right" */).then(c => wrapFunctional(c.default || c))
export const ChevronUp = () => import('../../components/icons/ChevronUp.vue' /* webpackChunkName: "components/chevron-up" */).then(c => wrapFunctional(c.default || c))
export const Clock = () => import('../../components/icons/Clock.vue' /* webpackChunkName: "components/clock" */).then(c => wrapFunctional(c.default || c))
export const CloseIcon = () => import('../../components/icons/CloseIcon.vue' /* webpackChunkName: "components/close-icon" */).then(c => wrapFunctional(c.default || c))
export const CloseIconVue = () => import('../../components/icons/CloseIcon.vue.vue' /* webpackChunkName: "components/close-icon-vue" */).then(c => wrapFunctional(c.default || c))
export const Cog = () => import('../../components/icons/Cog.vue' /* webpackChunkName: "components/cog" */).then(c => wrapFunctional(c.default || c))
export const CrossIcon = () => import('../../components/icons/CrossIcon.vue' /* webpackChunkName: "components/cross-icon" */).then(c => wrapFunctional(c.default || c))
export const DocumentPlus = () => import('../../components/icons/DocumentPlus.vue' /* webpackChunkName: "components/document-plus" */).then(c => wrapFunctional(c.default || c))
export const Download = () => import('../../components/icons/Download.vue' /* webpackChunkName: "components/download" */).then(c => wrapFunctional(c.default || c))
export const DropArea = () => import('../../components/icons/DropArea.vue' /* webpackChunkName: "components/drop-area" */).then(c => wrapFunctional(c.default || c))
export const FitIcon = () => import('../../components/icons/FitIcon.vue' /* webpackChunkName: "components/fit-icon" */).then(c => wrapFunctional(c.default || c))
export const Folder = () => import('../../components/icons/Folder.vue' /* webpackChunkName: "components/folder" */).then(c => wrapFunctional(c.default || c))
export const Identification = () => import('../../components/icons/Identification.vue' /* webpackChunkName: "components/identification" */).then(c => wrapFunctional(c.default || c))
export const ImagePlaceholder = () => import('../../components/icons/ImagePlaceholder.vue' /* webpackChunkName: "components/image-placeholder" */).then(c => wrapFunctional(c.default || c))
export const InvalidIcon = () => import('../../components/icons/InvalidIcon.vue' /* webpackChunkName: "components/invalid-icon" */).then(c => wrapFunctional(c.default || c))
export const LogoISP = () => import('../../components/icons/LogoISP.vue' /* webpackChunkName: "components/logo-i-s-p" */).then(c => wrapFunctional(c.default || c))
export const Logout = () => import('../../components/icons/Logout.vue' /* webpackChunkName: "components/logout" */).then(c => wrapFunctional(c.default || c))
export const Pencil = () => import('../../components/icons/Pencil.vue' /* webpackChunkName: "components/pencil" */).then(c => wrapFunctional(c.default || c))
export const Play = () => import('../../components/icons/Play.vue' /* webpackChunkName: "components/play" */).then(c => wrapFunctional(c.default || c))
export const Plus = () => import('../../components/icons/Plus.vue' /* webpackChunkName: "components/plus" */).then(c => wrapFunctional(c.default || c))
export const Refresh = () => import('../../components/icons/Refresh.vue' /* webpackChunkName: "components/refresh" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/icons/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const Trash = () => import('../../components/icons/Trash.vue' /* webpackChunkName: "components/trash" */).then(c => wrapFunctional(c.default || c))
export const XCircle = () => import('../../components/icons/XCircle.vue' /* webpackChunkName: "components/x-circle" */).then(c => wrapFunctional(c.default || c))
export const ZoomIn = () => import('../../components/icons/ZoomIn.vue' /* webpackChunkName: "components/zoom-in" */).then(c => wrapFunctional(c.default || c))
export const ZoomOut = () => import('../../components/icons/ZoomOut.vue' /* webpackChunkName: "components/zoom-out" */).then(c => wrapFunctional(c.default || c))
export const AppButton = () => import('../../components/general/AppButton.vue' /* webpackChunkName: "components/app-button" */).then(c => wrapFunctional(c.default || c))
export const AppLink = () => import('../../components/general/AppLink.vue' /* webpackChunkName: "components/app-link" */).then(c => wrapFunctional(c.default || c))
export const BareLink = () => import('../../components/general/BareLink.vue' /* webpackChunkName: "components/bare-link" */).then(c => wrapFunctional(c.default || c))
export const BottomRightControls = () => import('../../components/general/BottomRightControls.vue' /* webpackChunkName: "components/bottom-right-controls" */).then(c => wrapFunctional(c.default || c))
export const Container = () => import('../../components/ui/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c))
export const CustomDeselect = () => import('../../components/ui/CustomDeselect.vue' /* webpackChunkName: "components/custom-deselect" */).then(c => wrapFunctional(c.default || c))
export const CustomOpenIndicator = () => import('../../components/ui/CustomOpenIndicator.vue' /* webpackChunkName: "components/custom-open-indicator" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/ui/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const TransitionExpand = () => import('../../components/ui/TransitionExpand.vue' /* webpackChunkName: "components/transition-expand" */).then(c => wrapFunctional(c.default || c))
export const Typo = () => import('../../components/ui/Typo.vue' /* webpackChunkName: "components/typo" */).then(c => wrapFunctional(c.default || c))
export const HeaderNav = () => import('../../components/menus/HeaderNav.vue' /* webpackChunkName: "components/header-nav" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../components/forms/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const InputField = () => import('../../components/forms/InputField.vue' /* webpackChunkName: "components/input-field" */).then(c => wrapFunctional(c.default || c))
export const SelectBox = () => import('../../components/forms/SelectBox.vue' /* webpackChunkName: "components/select-box" */).then(c => wrapFunctional(c.default || c))
export const TextAreaField = () => import('../../components/forms/TextAreaField.vue' /* webpackChunkName: "components/text-area-field" */).then(c => wrapFunctional(c.default || c))
export const SearchInputField = () => import('../../components/forms/search/InputField.vue' /* webpackChunkName: "components/search-input-field" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckbox = () => import('../../components/forms/base/Checkbox.vue' /* webpackChunkName: "components/base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseInputField = () => import('../../components/forms/base/InputField.vue' /* webpackChunkName: "components/base-input-field" */).then(c => wrapFunctional(c.default || c))
export const FloorModal = () => import('../../components/plan-editor/FloorModal.vue' /* webpackChunkName: "components/floor-modal" */).then(c => wrapFunctional(c.default || c))
export const OfferGenerationModal = () => import('../../components/plan-editor/OfferGenerationModal.vue' /* webpackChunkName: "components/offer-generation-modal" */).then(c => wrapFunctional(c.default || c))
export const PlanUploader = () => import('../../components/plan-editor/PlanUploader.vue' /* webpackChunkName: "components/plan-uploader" */).then(c => wrapFunctional(c.default || c))
export const Product = () => import('../../components/plan-editor/Product.vue' /* webpackChunkName: "components/product" */).then(c => wrapFunctional(c.default || c))
export const ProductCategories = () => import('../../components/plan-editor/ProductCategories.vue' /* webpackChunkName: "components/product-categories" */).then(c => wrapFunctional(c.default || c))
export const ProductImage = () => import('../../components/plan-editor/ProductImage.vue' /* webpackChunkName: "components/product-image" */).then(c => wrapFunctional(c.default || c))
export const ProductPlaner = () => import('../../components/plan-editor/ProductPlaner.vue' /* webpackChunkName: "components/product-planer" */).then(c => wrapFunctional(c.default || c))
export const RoomDrawer = () => import('../../components/plan-editor/RoomDrawer.vue' /* webpackChunkName: "components/room-drawer" */).then(c => wrapFunctional(c.default || c))
export const RoomElement = () => import('../../components/plan-editor/RoomElement.vue' /* webpackChunkName: "components/room-element" */).then(c => wrapFunctional(c.default || c))
export const ProjectInfosForm = () => import('../../components/project/ProjectInfosForm.vue' /* webpackChunkName: "components/project-infos-form" */).then(c => wrapFunctional(c.default || c))
export const ProjectItem = () => import('../../components/project/ProjectItem.vue' /* webpackChunkName: "components/project-item" */).then(c => wrapFunctional(c.default || c))
export const ProjectList = () => import('../../components/project/ProjectList.vue' /* webpackChunkName: "components/project-list" */).then(c => wrapFunctional(c.default || c))
export const ProjectSettingsForm = () => import('../../components/project/ProjectSettingsForm.vue' /* webpackChunkName: "components/project-settings-form" */).then(c => wrapFunctional(c.default || c))
export const ProjectStatusBadge = () => import('../../components/project/ProjectStatusBadge.vue' /* webpackChunkName: "components/project-status-badge" */).then(c => wrapFunctional(c.default || c))
export const SettingsForm = () => import('../../components/global-settings/SettingsForm.vue' /* webpackChunkName: "components/settings-form" */).then(c => wrapFunctional(c.default || c))
export const BasicContent = () => import('../../components/BasicContent.vue' /* webpackChunkName: "components/basic-content" */).then(c => wrapFunctional(c.default || c))
export const RoomForm = () => import('../../components/room/RoomForm.vue' /* webpackChunkName: "components/room-form" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
