import { render, staticRenderFns } from "./HeaderNav.vue?vue&type=template&id=0220e2bd&scoped=true&"
import script from "./HeaderNav.vue?vue&type=script&lang=js&"
export * from "./HeaderNav.vue?vue&type=script&lang=js&"
import style0 from "./HeaderNav.vue?vue&type=style&index=0&id=0220e2bd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0220e2bd",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoISP: require('/vercel/path0/components/icons/LogoISP.vue').default})
