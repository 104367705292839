export default function ({ $axios, error: nuxtError }) {
  $axios.onError((error) => {
    const code = parseInt(error.response?.status)

    if (code === 503) {
      nuxtError({ statusCode: 503 })
      // https://axios.nuxtjs.org/helpers/#interceptors
      return Promise.resolve(false)
    }

    if (code === 403 && process.server) {
      nuxtError({ statusCode: 403 })
      // https://axios.nuxtjs.org/helpers/#interceptors
      return Promise.resolve(false)
    }
  })
}
