import { render, staticRenderFns } from "./sidebar.vue?vue&type=template&id=9fb84482&scoped=true&"
import script from "./sidebar.vue?vue&type=script&lang=js&"
export * from "./sidebar.vue?vue&type=script&lang=js&"
import style0 from "./sidebar.vue?vue&type=style&index=0&id=9fb84482&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fb84482",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoISP: require('/vercel/path0/components/icons/LogoISP.vue').default,Folder: require('/vercel/path0/components/icons/Folder.vue').default,AppLink: require('/vercel/path0/components/general/AppLink.vue').default,Cog: require('/vercel/path0/components/icons/Cog.vue').default,Play: require('/vercel/path0/components/icons/Play.vue').default,Clock: require('/vercel/path0/components/icons/Clock.vue').default,CheckBadge: require('/vercel/path0/components/icons/CheckBadge.vue').default,XCircle: require('/vercel/path0/components/icons/XCircle.vue').default,Logout: require('/vercel/path0/components/icons/Logout.vue').default})
