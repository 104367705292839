export const state = () => ({
  projects: [],
  totalProjects: 0,
})

export const actions = {
  async fetchProjects({ commit }, options = {}) {
    const { search, filters } = {
      search: null,
      filters: [],
      ...options,
    }

    const url = '/projects'

    const params = []

    if (search) {
      params.push(`search=${encodeURIComponent(search)}`)
    }

    if (filters?.length > 0) {
      // append filter as param. a filter consists of key and value
      // e.g. filter[status]=active
      for (const filter of filters) {
        for (const key in filter) {
          const encodedValue = encodeURIComponent(filter[key])
          params.push(`filters[${key}]=${encodedValue}`)
        }
      }
    }

    const query = params.join('&')

    const { data, total } = await this.$axios.$get(url, {
      params: {
        // dummy param to satisfy axios (this isn't actually used)
        dummy: 1,
      },
      paramsSerializer: () => query, // prevent axios from encoding params
    })
    commit('setProjects', data)
    commit('setTotalProjects', total)
  },

  async fetchProject({ commit }, { id, include = null }) {
    const url = `/projects/${id}`
    let params = {}
    if (include) {
      params = { with: include }
    }

    const { data } = await this.$axios.$get(url, { params })
    return data
  },

  async createProject({ commit }, project) {
    const { data } = await this.$axios.$post('/projects', project)
    commit('addProject', data)
    return data
  },

  async updateProject({ commit }, { projectId, projectData }) {
    const { data } = await this.$axios.$put(
      `/projects/${projectId}`,
      projectData
    )
    return data
  },

  async deleteProject({ commit }, projectId) {
    await this.$axios.$delete(`/projects/${projectId}`)
  },
}

export const mutations = {
  setProjects(state, projects) {
    state.projects = projects
  },

  setTotalProjects(state, totalProjects) {
    state.totalProjects = totalProjects
  },

  addProject(state, project) {
    state.projects.push(project)
  },
}
