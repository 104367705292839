import VuexPersistence from 'vuex-persist'

export const state = () => ({
  dropdownOpen: false,
  sidebarCollapsed: false,
})

export const actions = {}

export const mutations = {
  toggleDropdown(state) {
    state.dropdownOpen = !state.dropdownOpen
  },
  toggleSidebar(state) {
    state.sidebarCollapsed = !state.sidebarCollapsed
  },
  setSidebarCollapsed(state, value) {
    state.sidebarCollapsed = value
  },
}

export const getters = {}

export const plugins = [new VuexPersistence().plugin]
