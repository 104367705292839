export const state = () => ({
  productCategories: [],
})

export const actions = {
  async fetchAll({ commit }) {
    const { data } = await this.$axios.$get('/product-categories')
    commit('setAll', data)
  },
}

export const mutations = {
  setAll(state, productCategories) {
    state.productCategories = productCategories
  },
}

export const getters = {}
