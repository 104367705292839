export const state = () => ({})

let fetchFloorCancelToken = null

export const actions = {
  async fetchFloor({ commit }, { projectId, floorId }) {
    if (fetchFloorCancelToken) {
      fetchFloorCancelToken.cancel()
    }

    fetchFloorCancelToken = this.$axios.CancelToken.source()

    const { data } = await this.$axios.$get(
      `/projects/${projectId}/floors/${floorId}`,
      {
        cancelToken: fetchFloorCancelToken.token,
      }
    )
    return data
  },

  async createFloor({ commit }, { projectId, name }) {
    const { data } = await this.$axios.$post(`/projects/${projectId}/floors`, {
      name,
    })

    return data
  },

  async updateFloor({ commit }, { projectId, floorId, floorData }) {
    const { data } = await this.$axios.$put(
      `/projects/${projectId}/floors/${floorId}`,
      floorData
    )

    return data
  },

  async uploadFloorPlan({ commit }, { projectId, floorId, file }) {
    const formData = new FormData()
    formData.append('file', file)

    const { data } = await this.$axios.$post(
      `/projects/${projectId}/floors/${floorId}/floor-plan`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    return data
  },

  async deleteFloor({ commit }, { projectId, floorId }) {
    await this.$axios.$delete(`/projects/${projectId}/floors/${floorId}`)
  },
}

export const mutations = {}

export const getters = {}
