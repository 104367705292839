import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7d9c04c3 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _d9f9f28e = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _0239eedf = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _70d4d26f = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _35bffa13 = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _ac18d9ea = () => interopDefault(import('../pages/projects/create.vue' /* webpackChunkName: "pages/projects/create" */))
const _74a84d0e = () => interopDefault(import('../pages/projects/_project/settings.vue' /* webpackChunkName: "pages/projects/_project/settings" */))
const _11de03c2 = () => interopDefault(import('../pages/projects/_project/infos.vue' /* webpackChunkName: "pages/projects/_project/infos" */))
const _2d8c7a8a = () => interopDefault(import('../pages/projects/_project/product-planer.vue' /* webpackChunkName: "pages/projects/_project/product-planer" */))
const _6cbaaa3d = () => interopDefault(import('../pages/projects/_project/room-drawer.vue' /* webpackChunkName: "pages/projects/_project/room-drawer" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/anmelden",
    component: _7d9c04c3,
    name: "login___de_CH"
  }, {
    path: "/einstellungen",
    component: _d9f9f28e,
    name: "settings___de_CH"
  }, {
    path: "/passwort-vergessen",
    component: _0239eedf,
    name: "forgot-password___de_CH"
  }, {
    path: "/passwort-zuruecksetzen",
    component: _70d4d26f,
    name: "reset-password___de_CH"
  }, {
    path: "/projekte",
    component: _35bffa13,
    name: "projects___de_CH"
  }, {
    path: "/projekte/erstellen",
    component: _ac18d9ea,
    name: "projects-create___de_CH"
  }, {
    path: "/projekte/:project/einstellungen",
    component: _74a84d0e,
    name: "projects-project-settings___de_CH"
  }, {
    path: "/projekte/:project/informationen",
    component: _11de03c2,
    name: "projects-project-infos___de_CH"
  }, {
    path: "/projekte/:project/produkte-planen",
    component: _2d8c7a8a,
    name: "projects-project-product-planer___de_CH"
  }, {
    path: "/projekte/:project/raum-zeichner",
    component: _6cbaaa3d,
    name: "projects-project-room-drawer___de_CH"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___de_CH"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
