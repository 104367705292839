export const state = () => ({})

export const actions = {
  async login({ dispatch }, { email, password, remember = false }) {
    await this.$auth.loginWith('laravelSanctum', {
      data: {
        email,
        password,
        remember,
      },
    })
  },

  async logout({ commit }) {
    await this.$auth.logout()
  },

  async sendResetLink({ commit }, { email }) {
    const response = await this.$axios.$post('/forgot-password', {
      email,
    })

    return response
  },

  async resetPassword(
    { commit },
    // eslint-disable-next-line camelcase
    { token, email, password, password_confirmation }
  ) {
    const response = await this.$axios.$post('/reset-password', {
      token,
      email,
      password,
      // eslint-disable-next-line camelcase
      password_confirmation,
    })

    return response
  },
}

export const mutations = {}

export const getters = {
  currentUser(_, _2, rootState) {
    return rootState.auth.user ?? {}
  },
}
