
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'

export default {
  data() {
    const fullSize = this.getFullSizeBasedOnCurrentRoute()
    return {
      fullSize: fullSize ?? false,
    }
  },

  computed: {
    ...mapState('project', ['totalProjects']),
    ...mapGetters('user', ['currentUser']),
  },

  watch: {
    $route() {
      const fullSize = this.getFullSizeBasedOnCurrentRoute()
      this.fullSize = fullSize ?? false
      this.setSidebarCollapsed(!this.fullSize)
    },
  },

  created() {
    const fullSize = this.getFullSizeBasedOnCurrentRoute()
    this.fullSize = fullSize ?? false
    this.setSidebarCollapsed(!this.fullSize)
  },

  methods: {
    ...mapMutations('navigation', ['setSidebarCollapsed']),
    ...mapActions('user', ['logout']),
    getFullSizeBasedOnCurrentRoute() {
      const reversedMatches = [...this.$route.matched].reverse()

      const components = reversedMatches.map(
        (route) => route.components.default
      )

      const firstComponentWithFullSizeNavigationConfig = components.find(
        (component) => typeof component.options.fullSizeNavigation === 'boolean'
      )

      return firstComponentWithFullSizeNavigationConfig?.options
        .fullSizeNavigation
    },
  },
}
