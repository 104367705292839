import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required,
  confirmed,
  min,
  max,
  size,
  ext,
  email,
  numeric,
  length,
} from 'vee-validate/dist/rules'

extend('required', required)
extend('accept', {
  validate: (value) => ({
    valid: value === true,
    required: true,
  }),
  computesRequired: true,
})

extend('confirmed', confirmed)
extend('min', min)
extend('max', max)
extend('size', size)
extend('ext', ext)
extend('email', email)
extend('numeric', numeric)
extend('length', length)
extend('phone', (value) => {
  // https://regex101.com/r/ooKq2J/1
  return /^(\+|0|00)([0-9]){9,}$/.test(value.replace(/\s/g, ''))
})

extend('url', (value) => {
  if (value) {
    return /https?:\/\/(?:w{1,3}\.)?[^\s.]+(?:\.[a-z]+)*(?::\d+)?(?![^<]*(?:<\/\w+>|\/?>))/.test(
      value
    )
  }

  return false
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
