export const state = () => ({
  fixcostPositions: [],
})

export const actions = {
  async fetchAll({ commit }) {
    const { data } = await this.$axios.$get('/fixcost-positions')
    commit('setAll', data)
  },
}

export const mutations = {
  setAll(state, fixcostPositions) {
    state.fixcostPositions = fixcostPositions
  },
}

export const getters = {}
