export default ({ app, $auth, store }) => {
  const redirect = { ...$auth.$storage.options.redirect }

  const localizeRedirects = () => {
    for (const key in redirect) {
      $auth.$storage.options.redirect[key] = app.localePath(redirect[key])
    }
  }

  localizeRedirects()
}
