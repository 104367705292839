export const state = () => ({})

export const actions = {
  async fetchSettings({ commit }) {
    const { data } = await this.$axios.$get('/global-settings')
    return data
  },
}

export const mutations = {}

export const getters = {}
