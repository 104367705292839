
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState('navigation', {
      dropdownOpen: (state) => state.dropdownOpen,
    }),
  },

  methods: {
    ...mapMutations('navigation', ['toggleDropdown']),
  },
}
